import React from 'react';

export const NotAuthenticated: React.FC = () => {
    return (
        <div>
            <h1>Access Denied</h1>
            <p>You are not authenticated to access this content</p>
        </div>
    );
};
