import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { GlobalStyle } from '../globalStyle/GlobalStyle.style';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { configureStore } from '@makemydeal/dr-shared-store';
import { getInitialMiddleware } from '../shells/main/store/initialMiddleware';
import { getRemainingMiddleware } from '../shells/main/store/remainingMiddleware';
import { initialReducers } from '../shells/main/reducers/initialReducers';
import { setOktaAuthForInterceptors, setupAuthNetworkIntercepts } from '@makemydeal/dr-shared-network';
import OktaAuth from '@okta/okta-auth-js';
import { MemoryRouter } from '@makemydeal/dr-react-18-wrappers';
import { Routes } from './components/Routes';
import { OPEN_DEAL_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

export interface NewDealModuleProps {
    commonOrgId: string;
    oktaAuth: OktaAuth;
}

const state = {};
const allMiddleware = getInitialMiddleware().concat(getRemainingMiddleware());

export function NewDealModule({ oktaAuth }: NewDealModuleProps) {
    const storeRef = useRef(configureStore(state, allMiddleware, initialReducers));

    useEffect(() => {
        setupAuthNetworkIntercepts(OPEN_DEAL_APP_PREFIX);
        setOktaAuthForInterceptors(oktaAuth);
    }, [oktaAuth]);

    const routesProps = {
        oktaAuth: oktaAuth
    };

    return (
        <>
            <GlobalStyle />
            <InterstateThemeProvider
                themeName="Interstate"
                applicationName="Open Deal"
                scopeName="Open Deal"
                themeRegistries={[interstateThemeRegistry]}
            >
                <ReduxProvider store={storeRef.current}>
                    <MemoryRouter>
                        <Routes {...routesProps} />
                    </MemoryRouter>
                </ReduxProvider>
            </InterstateThemeProvider>
        </>
    );
}

export default NewDealModule;
