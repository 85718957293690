// externals
import React, { PropsWithChildren } from 'react';
import { MemoryRouter as TypedMemoryRouter, MemoryRouterProps } from 'react-router-dom';

// NOTE: This is necessary because React v18 requires props to explictly define that they accept children or not.
//   Prior to React v18 "FC" components inherited this ability by default.  Since this is simply a typing thing and
//   doesn't have any impact at runtime we can simply force compatibility by doing this.  Obviously when we switch
//   everything over to "React v18" friendly libraries this will no longer be an issue so this is just a temporary
//   solution.
const TypelessMemoryRouter = TypedMemoryRouter as any;

export type TypedMemoryRouterProps = PropsWithChildren<MemoryRouterProps>;

export const MemoryRouter: React.FC<TypedMemoryRouterProps> = (props) => (
    <TypelessMemoryRouter {...props}>{props.children}</TypelessMemoryRouter>
);
