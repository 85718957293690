import React, { useCallback, useMemo } from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { Route, Switch, useHistory } from 'react-router-dom';
import { NewDealForm } from '../new-deal-form';
import { NotAuthenticated } from './NotAuthenticated';
import { SecureRoute, Security } from '@okta/okta-react';

export type RoutesProps = {
    oktaAuth: OktaAuth;
    byPassOkta?: boolean;
};

export const Routes: React.FC<RoutesProps> = ({ oktaAuth, byPassOkta }) => {
    const restoreOriginalUri = useCallback((_oktaAuth: OktaAuth, _originalUrl: string) => {}, []);
    const history = useHistory();

    const isOktaDisabled = useMemo(() => process.env.BYPASS_OKTA === 'true' || byPassOkta, [byPassOkta]);

    const customAuthHandler = (_oktaAuth: OktaAuth) => {
        history.replace('/not-authenticated');
    };

    if (isOktaDisabled) {
        return <NewDealForm />;
    }

    return (
        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
                <Route path="/not-authenticated" exact>
                    <NotAuthenticated />
                </Route>
                <SecureRoute path="*">
                    <NewDealForm />
                </SecureRoute>
            </Switch>
        </Security>
    );
};
